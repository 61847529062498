import React, { Component } from "react";
import Transition from "../components/transition";
import SiteMetadata from "../components/site-metadata";
import MenuLinks from "../components/MenuLinks";
import Headroom from "react-headroom";
import "../css/all.scss";

class Layout extends Component {
  render() {
    const { children, location } = this.props;
    const { pathname } = location;
    return (
      <div>
        <SiteMetadata pathname={location.pathname} />
        {pathname !== "/" && (
          <Headroom>
            <MenuLinks pathname={pathname} />
          </Headroom>
        )}
        <Transition location={location}>{children}</Transition>
      </div>
    );
  }
}
Layout.defaultProps = {
  location: {},
};
export default Layout;
