import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";

import { TweenMax, Power3 } from "gsap";
import Menu from "../data/menu";

const MenuItem = ({ name, url, isActive }) => (
  <li>
    <Link className={`hero-link ${isActive ? "is-active" : ""}`} to={url}>
      {name}
    </Link>
  </li>
);

function MenuLinks({ pathname }) {
  let menuRef = useRef(null);
  useEffect(() => {
    let refMenuItems = menuRef.children;
    TweenMax.staggerFrom(
      refMenuItems, // array
      1, // duration
      {
        opacity: 0,
        ease: Power3.easeOut,
      }, // vars
      0.2 // delay
    );
  }, []);
  return (
    <ul ref={(el) => (menuRef = el)} className={`hero-links`}>
      {Menu.map(({ name, url }) => {
        const isActive = pathname === url;
        return (
          <MenuItem key={name} name={name} url={url} isActive={isActive} />
        );
      })}
    </ul>
  );
}

export default MenuLinks;
