import React, { useEffect, useRef } from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

import { TimelineLite, Power3 } from "gsap";
import * as styles from "./transition.style";

const timeout = 2500;

const Background = ({ children }) => {
  let container = useRef(null);
  const tl = new TimelineLite();
  useEffect(() => {
    tl.to(container, 1.5, {
      css: { visibility: "visible", opacity: 1 },
      ease: Power3.easeOut,
    });
  }, []);
  return (
    <div ref={(el) => (container = el)} id="test" className={styles.container}>
      {children}
    </div>
  );
};

function Transition({ children, location }) {
  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {(status) => (
          <Background children={children} status={status} location={location} />
        )}
      </ReactTransition>
    </TransitionGroup>
  );
}

export default Transition;
