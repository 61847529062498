import React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

const SiteMetadata = ({ pathname }) => (
  <StaticQuery
    query={graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            siteUrl
            title
            twitter
            description
            headline
            ogImage
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          siteUrl,
          title,
          twitter,
          description,
          headline,
          ogImage,
        },
      },
    }) => (
      <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
        <html lang="en" />
        <link rel="canonical" href={`${siteUrl}${pathname}`} />
        <meta name="description" content={description} />
        <meta name="docsearch:version" content="2.0" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={title} />
        <meta property="og:title" content={headline} />
        <meta property="og:image" content={`${siteUrl}${ogImage}`} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={twitter} />
        <meta name="twitter:image" content={`${siteUrl}${ogImage}`} />
        <meta name="twitter:creator" content={twitter} />
        {/* <script>
          window.loadPromise = new Promise(resolve =>{" "}
          {window.addEventListener("DOMContentLoaded", resolve())})
        </script> */}
      </Helmet>
    )}
  />
);

export default SiteMetadata;
